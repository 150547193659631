export default class TaskAPI {
  constructor(args) {
    this.request = args.request
  }

  createTaskProject = data => this.request.post('/Tasks/Projects', data)

  createTaskList = data => this.request.post('/Tasks/Lists', data)

  getTaskProjects = params => this.request
    .get('/Tasks/Projects', { params })

  getTaskLists = params => this.request.get('/Tasks/Lists', { params })

  getTaskProjectById = id => this.request.get(`/Tasks/Projects?id=${id}`)

  getTaskListById = id => this.request.get(`/Tasks/List/${id}`)

  updateTaskList = (id, data) => this.request.put(`/Tasks/List/${id}`, data)

  deleteTaskList = id => this.request.del(`/Tasks/List/${id}`)

  archiveTaskList = id => this.request.put(`/Tasks/List/${id}/Archive`)

  createTaskItem = data => this.request.post('/Tasks/Items', data)

  getTaskItems = params => this.request.get('/Tasks/Items', { params })

  getTaskItemById = id => this.request.get(`/Tasks/Item/${id}`)

  updateTaskItem = (id, data) => this.request.put(`/Tasks/Item/${id}`, data)

  deleteTaskItem = id => this.request.del(`/Tasks/Item/${id}`)

  getCompletedTaskItems = params => this.request.get('/Tasks/Items/Completed', { params })

  getTaskItemsByListId = (listId, params) => this.request.get(`/Tasks/List/${listId}/Items`, { params })

  getUserTaskItems = params => this.request.get('/Users/Tasks/Items', { params })

  getUpcomingUserTaskItems = params => this.request.get('/Users/Tasks/Items/Upcoming', { params })

  archiveTaskItem = id => this.request.put(`/Tasks/Item/${id}/Archive`)
}
