import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { conditionedClassName } from 'flynk.app.web.core.components/helpers'
import Panel from './Panel'

export const Collapse = observer(({
  children,
  className,
}) => (
  <div
    className={conditionedClassName({
      collapse__wrapper: true,
      [className]: className,
    })}
  >
    {children}
  </div>
))

Collapse.Panel = Panel

Collapse.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
}

Collapse.defaultProps = {
  className: '',
}
