import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import TaskOptionSelect from '../../engyn.base.apps.web.shared/components/Task/TaskOptionSelect'

const TaskOptions = ({
  priority,
  due,
  assignee,
  manager,
  creator,
  lastUpdated,
  created,
  nextReminder,
}) => (
  <div className="task-options">
    <TaskOptionSelect
      icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'arrow-square-up']} />}
      placeholder="Priority"
      defaultValue={priority}
      options={[
        { value: 'low', label: 'Low' },
        { value: 'middle', label: 'Middle' },
        { value: 'high', label: 'High' },
      ]}
    />

    <TaskOptionSelect
      icon={<FontAwesomeIcon color="blue" size="2x" icon={['fal', 'calendar-check']} />}
      placeholder="Due"
      defaultValue={due}
      options={[
        { value: 'jan', label: 'January' },
        { value: 'feb', label: 'February' },
        { value: 'mar', label: 'March' },
      ]}
    />

    <TaskOptionSelect
      icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'user-circle']} />}
      placeholder="Assignee"
      defaultValue={assignee}
      options={[
        { value: 'user1', label: 'User1' },
        { value: 'user2', label: 'User2' },
        { value: 'user3', label: 'User3' },
      ]}
    />

    <TaskOptionSelect
      icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'user-circle']} />}
      placeholder="Manager"
      value={manager}
      readonly
      options={[
        { value: 'user1', label: 'User1' },
        { value: 'user2', label: 'User2' },
        { value: 'user3', label: 'User3' },
      ]}
    />

    <TaskOptionSelect
      icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'user-circle']} />}
      placeholder="Creator"
      value={creator}
      readonly
      options={[
        { value: 'user1', label: 'User1' },
        { value: 'user2', label: 'User2' },
        { value: 'user3', label: 'User3' },
      ]}
    />
    <TaskOptionSelect
      icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'clock']} />}
      placeholder="Last Updated"
      value={lastUpdated}
      readonly
      options={[
        { value: 'jan', label: 'January' },
        { value: 'feb', label: 'February' },
        { value: 'mar', label: 'March' },
      ]}
    />
    <TaskOptionSelect
      icon={<FontAwesomeIcon color="gray" size="2x" icon={['fal', 'clock']} />}
      placeholder="Created"
      value={created}
      readonly
      options={[
        { value: 'jan', label: 'January' },
        { value: 'feb', label: 'February' },
        { value: 'mar', label: 'March' },
      ]}
    />
    <TaskOptionSelect
      icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'alarm-clock']} />}
      placeholder="Next Reminder"
      defaultValue={nextReminder}
      options={[
        { value: 'jan', label: 'January' },
        { value: 'feb', label: 'February' },
        { value: 'mar', label: 'March' },
      ]}
    />
  </div>
)

TaskOptions.propTypes = {
  priority: PropTypes.string,
  due: PropTypes.string,
  assignee: PropTypes.string,
  manager: PropTypes.string,
  creator: PropTypes.string,
  lastUpdated: PropTypes.string,
  created: PropTypes.string,
  nextReminder: PropTypes.string,
}

TaskOptions.defaultProps = {
  priority: '',
  due: '',
  assignee: '',
  manager: '',
  creator: '',
  lastUpdated: '',
  created: '',
  nextReminder: '',
}

export default TaskOptions
