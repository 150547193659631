import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { observer, PropTypes as MobXPropTypes } from 'mobx-react'
import { useStores } from 'flynk.app.web.core.components/helpers'

import TaskMenuItem from '../../components/Navigation/TaskMenuItem'
import { TASK_MANAGEMENT_NAVIGATION } from '../../engyn.base.apps.web.shared/constants'
import { Collapse } from '../../engyn.base.apps.web.shared/components/Collapse/Collapse'
import Menu from '../../engyn.base.apps.web.shared/components/Menu/Menu'
import CreateNewProjectModalContainers from './Project/CreateNewProjectModalContainer'
import CreateNewListModalContainer from './List/CreateNewListModalContainer'
import Loading from '../../flynk.app.web.core.components/components/Loading'

const { Panel } = Collapse

const TaskManagementNavigationContainer = ({ handleSelectPeriod, period }) => {
  const {
    taskStore: {
      createListModalVisible,
      createProjectModalVisible,
      toggleCreateListModalVisible,
      toggleCreateProjectModalVisible,
      getTaskProjects,
      taskProjects,
      getTaskProjectsLoading,
      getTaskListsLoading,
      getTaskLists,
      taskLists,
    },
  } = useStores()

  useEffect(() => {
    getTaskProjects()
    getTaskLists()
  }, [])

  const {
    Calendar,
    Focus,
    Lists,
    Priority,
    Projects,
    RecentlyCompleted,
    RecentlyViewed,
    Starred,
    Upcoming,
    SavedFilters,
  } = TASK_MANAGEMENT_NAVIGATION

  if (getTaskProjectsLoading || getTaskListsLoading) return <Loading />

  return (
    <>
      <div
        className="task-management-navigation__wrapper collapsible__col-content"
      >
        <Menu
          className="task-management-navigation-menu"
          mode="inline"
          defaultSelected={period}
          onSelect={handleSelectPeriod}
        >
          <TaskMenuItem
            name={Focus}
            title="Focus"
            icon={<FontAwesomeIcon icon={['fal', 'crosshairs']} />}
          />
          <TaskMenuItem
            name={Upcoming}
            title="Upcoming"
            icon={<FontAwesomeIcon icon={['fal', 'watch']} />}
          />
          <TaskMenuItem
            name={Priority}
            title="Priority"
            icon={<FontAwesomeIcon icon={['fal', 'arrow-square-up']} />}
          />
          <TaskMenuItem
            name={Starred}
            title="Starred"
            icon={<FontAwesomeIcon icon={['fal', 'stars']} />}
          />
          <Collapse>
            <Panel
              header={() => (
                <TaskMenuItem
                  name={Projects}
                  title="Projects"
                  icon={<FontAwesomeIcon icon={['fal', 'project-diagram']} />}
                  disabled
                />
              )}
              iconType="fas"
              isExpanded
              key="Projects"
            >
              {
                taskProjects
                  .map(el => (
                    <TaskMenuItem
                      key={`tp-${el.id}`} // tp - task project
                      name={`tp-${el.id}`}
                      title={el.name}
                      suffix={20}
                      icon={<div style={{ borderRadius: '50%', width: 15, height: 15, backgroundColor: el.color }} />}
                    />
                  ))
              }
              <Button
                onClick={toggleCreateProjectModalVisible}
              >
                New Project
              </Button>
            </Panel>
            <Panel
              header={() => (
                <TaskMenuItem
                  name={Lists}
                  title="Lists"
                  icon={<FontAwesomeIcon icon={['fal', 'tasks']} />}
                />
              )}
              iconType="fas"
              key="Lists"
            >
              {
                taskLists
                  .map(el => (
                    <TaskMenuItem
                      key={`tl-${el.id}`} // tl - task list
                      name={`tl-${el.id}`}
                      title={el.name}
                      suffix={10}
                      icon={<div style={{ borderRadius: '50%', width: 15, height: 15, backgroundColor: el.color }} />}
                    />
                  ))
              }
              <Button
                onClick={toggleCreateListModalVisible}
              >
                New List
              </Button>
            </Panel>
          </Collapse>
          <TaskMenuItem
            name={Calendar}
            title="Calendar"
            icon={<FontAwesomeIcon icon={['fal', 'calendar-check']} />}
          />
          <TaskMenuItem
            name={SavedFilters}
            title="Saved Filters"
            icon={<FontAwesomeIcon icon={['fal', 'bookmark']} />}
          />
          <TaskMenuItem
            name={RecentlyCompleted}
            title="Recently Completed"
            icon={<FontAwesomeIcon icon={['fal', 'clipboard-check']} />}
          />
          <TaskMenuItem
            name={RecentlyViewed}
            title="Recently Viewed"
            icon={<FontAwesomeIcon icon={['fal', 'history']} />}
          />
        </Menu>
      </div>
      {
        createProjectModalVisible &&
        <CreateNewProjectModalContainers
          onCancel={toggleCreateProjectModalVisible}
          visible={createProjectModalVisible}
        />
      }
      {
        createListModalVisible &&
        <CreateNewListModalContainer
          onCancel={toggleCreateListModalVisible}
          visible={createListModalVisible}
        />
      }
    </>
  )
}

TaskManagementNavigationContainer.propTypes = {
  handleSelectPeriod: PropTypes.func.isRequired,
  period: MobXPropTypes.arrayOrObservableArray.isRequired,
}

export default observer(TaskManagementNavigationContainer)
