import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'

const TaskOptionSelect = ({ icon, placeholder, options, readonly, ...rest }) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)

  const handleDropdownOpen = () => {
    if (readonly) return
    setDropdownOpened(!dropdownOpened)
  }

  return (
    <div className="task-option">
      <div className="task-option__icon">
        {icon}
      </div>
      <Select
        {...rest}
        open={dropdownOpened}
        placeholder={
          <div className="task-option__placeholder">
            {placeholder}:
            {!readonly && <FontAwesomeIcon color="rgba(0, 0, 0, 0.25)" icon={['fal', 'chevron-down']} />}
          </div>
        }
        className="task-option__select"
        dropdownClassName="task-option__dropdown"
        onDropdownVisibleChange={handleDropdownOpen}
      >
        {
          options?.map(option => (
            <Select.Option value={option.value} key={option.value}>
              <div className="task-option__placeholder">
                {placeholder}:
                {!readonly && <FontAwesomeIcon color="rgba(0, 0, 0, 0.25)" icon={['fal', 'chevron-down']} /> }
              </div>
              <div className="task-option__value">
                {option.label}
              </div>
            </Select.Option>
          ))
        }
      </Select>
    </div>
  )
}

TaskOptionSelect.propTypes = {
  options: MobxPropTypes.arrayOrObservableArray,
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
}

TaskOptionSelect.defaultProps = {
  options: [],
  icon: null,
  placeholder: '',
  readonly: false,
}

export default TaskOptionSelect
