export const TASK_MANAGEMENT_NAVIGATION = {
  Focus: '1',
  Upcoming: '2',
  Priority: '3',
  Starred: '4',
  Projects: '5',
  Lists: '6',
  Calendar: '7',
  SavedFilters: '8',
  RecentlyCompleted: '9',
  RecentlyViewed: '10',
}
