import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import RelatedTask from './RelatedTask'

const RelatedTasks = ({ relatedTasks }) => (
  <div className="task-related-tasks">
    <div className="task-related-tasks__heading">
      <h4>Related Tasks</h4>
      <Button type="link">
        <FontAwesomeIcon size="2x" icon={['fal', 'plus']} />
      </Button>
    </div>
    <div>
      { relatedTasks.map(task => (
        <RelatedTask key={task.date} name={task.taskName} status={task.status} />
      ))}
    </div>
  </div>
)

RelatedTasks.propTypes = {
  relatedTasks: MobxPropTypes.arrayOrObservableArray,
}

RelatedTasks.defaultProps = {
  relatedTasks: [],
}

export default RelatedTasks
