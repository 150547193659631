import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { createBrowserHistory } from 'history'

import AlertStore from 'flynk.app.web.core.data/stores/Alert/AlertStore'
import ClientAppStore from 'flynk.app.web.core.data/stores/ClientApp/ClientAppStore'
import ClientAppFeatureStore from 'flynk.app.web.core.data/stores/ClientApp/ClientAppFeatureStore'
import ClientAppShortlistStore from 'flynk.app.web.core.data/stores/ClientApp/ClientAppShortlistStore'
import ErrorsStore from 'flynk.app.web.core.data/stores/Errors/ErrorsStore'
import IssueStore from 'flynk.app.web.core.data/stores/Issue/IssueStore'
import ModalsStore from 'flynk.app.web.core.data/stores/Modals/ModalsStore'
import NavStore from 'flynk.app.web.core.data/stores/Navigation/NavStore'
import ProductFeatureStore from 'flynk.app.web.core.data/stores/Product/ProductFeatureStore'
import ProfileStore from 'flynk.app.web.core.data/stores/Profile/ProfileStore'
import UserStore from 'flynk.app.web.core.data/stores/User/UserStore'
import InvitationStore from 'flynk.app.web.core.data/stores/User/InvitationStore'

import RootAPI from '../api'
import AuthStore from '../engyn.base.apps.web.shared/stores/AuthStore'
import ConfigStore from '../engyn.base.apps.web.shared/stores/ConfigStore'
import TaskStore from './TaskStore'

const browserHistory = createBrowserHistory()
const routingStore = new RouterStore()

export const history = syncHistoryWithStore(browserHistory, routingStore)

class RootStore {
  constructor() {
    const rootAPI = new RootAPI({ rootStore: this })

    this.authStore = new AuthStore({ rootStore: this, rootAPI })
    this.alertStore = new AlertStore({ rootStore: this, rootAPI })
    this.clientAppStore = new ClientAppStore({ rootStore: this, rootAPI })
    this.clientAppFeatureStore = new ClientAppFeatureStore({
      rootStore: this,
      rootAPI,
    })
    this.clientAppShortlistStore = new ClientAppShortlistStore({
      rootStore: this,
      rootAPI,
    })
    this.configStore = new ConfigStore({ rootStore: this, rootAPI })
    this.errorsStore = new ErrorsStore({ rootStore: this, rootAPI })
    this.issueStore = new IssueStore({ rootStore: this, rootAPI })
    this.modalsStore = new ModalsStore({ rootStore: this, rootAPI })
    this.navStore = new NavStore({ rootStore: this, rootAPI })
    this.productFeatureStore = new ProductFeatureStore({ rootStore: this, rootAPI })
    this.profileStore = new ProfileStore({ rootStore: this, rootAPI })
    this.userStore = new UserStore({ rootStore: this, rootAPI })
    this.invitationStore = new InvitationStore({ rootStore: this, rootAPI })
    this.taskStore = new TaskStore({ rootStore: this, rootAPI })
    this.routingStore = routingStore
  }
}

export default new RootStore()
