import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu } from 'antd'
import PropTypes from 'prop-types'

const TaskLink = ({ external, title, url }) => (
  <div className="task-link">
    {external ?
      <FontAwesomeIcon className="task-link__icon" icon={['fal', 'external-link']} /> :
      <FontAwesomeIcon className="task-link__icon" icon={['fal', 'link']} />}
    <a className="task-link__anchor" href={url}>{title}</a>
    <Dropdown
      className="task-link__dropdown"
      overlay={
        <Menu onClick={() => null}>
          <Menu.Item key="1">Click me!</Menu.Item>
          <Menu.Item key="2">Click me!</Menu.Item>
          <Menu.Item key="3">Click me!</Menu.Item>
        </Menu>
      }
      trigger="click"
    >
      <Button type="link" className="task-list__header-button">
        <FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
      </Button>
    </Dropdown>
  </div>
)

TaskLink.propTypes = {
  external: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default TaskLink
