import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../../engyn.base.apps.web.shared/components/Menu/Menu'

const TaskMenuItem = props => (
  <Menu.Item
    className="task-menu-item"
    {...props}
  >
    <div className="task-menu-item-content">
      <div className="task-menu-item-content-icon">{props.icon}</div>
      <div className="task-menu-item-content-title">{props.title}</div>
      <div className="task-menu-item-content-suffix">{props.suffix}</div>
    </div>
    {props.selected && props.extra && (
      <div className="task-menu-item-extra-wrapper">
        {props.extra}
      </div>
    )}
  </Menu.Item>
)

TaskMenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  extra: PropTypes.node,
}
TaskMenuItem.defaultProps = {
  suffix: null,
  selected: false,
  extra: null,
}

export default TaskMenuItem
