import React, { useState } from 'react'
import { Checkbox, Input, Button } from 'antd'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TaskChecklistItem = ({
  id,
  text,
  value,
  checked,
  handleChangeActiveItem,
  active,
  attributes,
}) => {
  const [checklistItemText, setChecklistItemText] = useState(text)

  const handleChangeText = (e) => {
    setChecklistItemText(e.target.value)
  }

  return (
    <div role="presentation" key={value} onClick={() => handleChangeActiveItem(id)}>
      <div className="task-checklist__checkbox">
        <Checkbox
          defaultChecked={checked}
        />
        <Input
          className="task-checklist__input"
          value={checklistItemText}
          onChange={handleChangeText}
          suffix={(
            <div className="task-checklist__input-suffix">
              {attributes.isRequired && <FontAwesomeIcon icon={['fal', 'star-of-life']} />}
              {attributes.isBlocked && <FontAwesomeIcon icon={['fal', 'octagon']} /> }
              {attributes.isPaused && <FontAwesomeIcon icon={['fal', 'pause']} />}
              {attributes.isCritical && <FontAwesomeIcon icon={['fal', 'times-circle']} />}
              {attributes.isFlagged && <FontAwesomeIcon icon={['fal', 'flag']} />}
              {attributes.isResolved && <FontAwesomeIcon icon={['fal', 'check']} />}
            </div>
          )}
        />
      </div>
      {
        active && (
          <div className="task-checklist__actions">
            <Button type="link">
              <FontAwesomeIcon
                icon={['fal', 'star-of-life']}
                size="2x"
              />
            </Button>
            <Button type="link">
              <FontAwesomeIcon
                icon={['fal', 'octagon']}
                size="2x"
              />
            </Button>
            <Button type="link">
              <FontAwesomeIcon
                icon={['fal', 'pause']}
                size="2x"
              />
            </Button>
            <Button type="link">
              <FontAwesomeIcon
                icon={['fal', 'flag']}
                size="2x"
              />
            </Button>
            <Button type="link">
              <FontAwesomeIcon
                icon={['fal', 'times-circle']}
                size="2x"
              />
            </Button>
            <Button type="link">
              <FontAwesomeIcon
                icon={['fal', 'check']}
                size="2x"
              />
            </Button>
          </div>
        )
      }
    </div>
  )
}

TaskChecklistItem.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  attributes: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleChangeActiveItem: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  active: PropTypes.bool,
}

TaskChecklistItem.defaultProps = {
  active: false,
  id: '',
  text: '',
  value: '',
  checked: false,
}

export default TaskChecklistItem
