import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Radio } from 'antd'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStores } from 'flynk.app.web.core.components/helpers'

import { PROJECT_COLORS_OBJECT } from '../../../engyn.base.apps.web.shared/constants'
import DetailsModal from '../../../engyn.base.apps.web.shared/components/Modal/DetailsModal'
import ProjectColorSelection from '../../../engyn.base.apps.web.shared/components/Project/ProjectColorSelection'
import TaskOptionSelect from '../../../engyn.base.apps.web.shared/components/Task/TaskOptionSelect'

const formItemLayout = {
  labelCol: { md: 24, sm: 24 },
  wrapperCol: { md: 24, sm: 24 },
}

const { Item } = Form

const CreateNewProjectModalContainer = ({
  onCancel,
  visible,
}) => {
  const {
    taskStore: {
      createTaskProject,
      createTaskProjectLoading,
    },
  } = useStores()
  const [form] = Form.useForm()
  const [selectedColor, setSelectedColor] = useState(PROJECT_COLORS_OBJECT.Violet)

  const handleSelectColor = (color) => {
    setSelectedColor(color)
  }

  const handleSubmit = async (values) => {
    const data = {
      ...values,
      color: selectedColor,
    }

    createTaskProject(data)
  }

  return (
    <DetailsModal
      onCancel={onCancel}
      projectColor={selectedColor}
      titleText="Project"
      visible={visible}
      className="create-project__modal"
    >
      <h4>Create a new project</h4>
      <Form
        form={form}
        onFinish={handleSubmit}
      >
        <Item name="name" required label="Project Name" {...formItemLayout}>
          <Input placeholder="Enter a name" />
        </Item>
        <Item name="description" label="Description" {...formItemLayout}>
          <Input placeholder="Enter an optional description" />
        </Item>
        <Item name="color" label="Select a color" {...formItemLayout}>
          <ProjectColorSelection
            handleSelectColor={handleSelectColor}
            selectedColor={selectedColor}
          />
        </Item>
        <Item
          className="create-project-form__visibility"
          name="visibility"
          label="Visibility"
          initialValue="a"
          {...formItemLayout}
        >
          <Radio.Group size="large">
            <Radio.Button value="a">Everyone</Radio.Button>
            <Radio.Button value="b">Select People</Radio.Button>
            <Radio.Button value="c">Only Me</Radio.Button>
          </Radio.Group>
        </Item>
        <Item
          className="create-project-form__people"
          name="people"
          label={
            (
              <>
                <h4>People</h4>
                <Button type="link">
                  <FontAwesomeIcon size="2x" icon={['fal', 'plus']} />
                </Button>
              </>
            )
          }
          {...formItemLayout}
        >
          <Input placeholder="Add People to your Project" />
        </Item>
        <div className="create-project-form__options">
          <TaskOptionSelect
            icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'user-circle']} />}
            placeholder="Manager"
            options={[
              { value: 'user1', label: 'User1' },
              { value: 'user2', label: 'User2' },
              { value: 'user3', label: 'User3' },
            ]}
          />
          <TaskOptionSelect
            icon={<FontAwesomeIcon color="orange" size="2x" icon={['fal', 'user-circle']} />}
            placeholder="Assignee"
            options={[
              { value: 'user1', label: 'User1' },
              { value: 'user2', label: 'User2' },
              { value: 'user3', label: 'User3' },
            ]}
          />
        </div>
        {/* <Item */}
        {/*  className="create-project-form__icon" */}
        {/*  name="icon" */}
        {/*  label={ */}
        {/*    ( */}
        {/*      <> */}
        {/*        <h4>Icon</h4> */}
        {/*        <Button type="link"> */}
        {/*          <FontAwesomeIcon size="2x" icon={['fal', 'plus']} /> */}
        {/*        </Button> */}
        {/*      </> */}
        {/*    ) */}
        {/*  } */}
        {/*  {...formItemLayout} */}
        {/* > */}
        {/*  <Input placeholder="Select an image to represent your image" /> */}
        {/* </Item> */}
        <div className="create-project-form__buttons">
          <Item>
            <Button onClick={onCancel}>Cancel</Button>
          </Item>
          <Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={createTaskProjectLoading}
            >
              Save
            </Button>
          </Item>
        </div>
      </Form>
    </DetailsModal>
  )
}

CreateNewProjectModalContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}

export default observer(CreateNewProjectModalContainer)
