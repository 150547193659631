import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import TaskChecklistItem from './TaskChecklistItem'

const TaskChecklist = ({ checklist }) => {
  const [activeChecklistItem, setActiveChecklistItem] = useState(null)

  const handleChangeActiveItem = (active) => {
    setActiveChecklistItem(active)
  }

  return (
    <div className="task-checklist">
      <div className="task-checklist__heading">
        <h5>This is the task check list</h5>
        <Button type="link">
          <FontAwesomeIcon icon={['fal', 'edit']} />
        </Button>
      </div>
      {
        checklist.map(item => (
          <TaskChecklistItem
            attributes={item.attributes}
            id={item.id}
            key={item.id}
            handleChangeActiveItem={handleChangeActiveItem}
            active={activeChecklistItem === item.id}
            value={item.value}
            checked={item.checked}
            text={item.text}
          />
        ))
      }
    </div>
  )
}

TaskChecklist.propTypes = {
  checklist: MobxPropTypes.arrayOrObservableArray,
}

TaskChecklist.defaultProps = {
  checklist: [],
}

export default TaskChecklist
