import { COLLAPSED_COLUMNS_INDEXES } from '../engyn.base.apps.web.shared/constants'

export const getCollapsedColumnsProperties = (collapsibleRow, collapsedColumnIndex) => {
  const collapsibleRowWidth = collapsibleRow.clientWidth
  const thinColumnWidth = 80

  const minFirstColumnWidth = 300
  const minSecondColumnWidth = 350

  let firstColumnWidth
  let secondColumnWidth
  let thirdColumnWidth
  let firstColumnLeft
  let secondColumnLeft
  let thirdColumnLeft

  let totalWideColumnsWidth = collapsibleRowWidth

  if (collapsedColumnIndex === COLLAPSED_COLUMNS_INDEXES.first) {
    totalWideColumnsWidth = collapsibleRowWidth - thinColumnWidth
  }

  firstColumnWidth = totalWideColumnsWidth / 3

  if (firstColumnWidth < minFirstColumnWidth) firstColumnWidth = minFirstColumnWidth

  thirdColumnWidth = (totalWideColumnsWidth / 8) * 3
  if (thirdColumnWidth < minSecondColumnWidth) thirdColumnWidth = minSecondColumnWidth

  if (collapsedColumnIndex === COLLAPSED_COLUMNS_INDEXES.third) {
    secondColumnWidth = totalWideColumnsWidth - firstColumnWidth
    firstColumnLeft = 0
    secondColumnLeft = 0
    thirdColumnLeft = -thirdColumnWidth
  }

  if (collapsedColumnIndex === COLLAPSED_COLUMNS_INDEXES.first) {
    secondColumnWidth = totalWideColumnsWidth - thirdColumnWidth
    firstColumnLeft = thinColumnWidth - firstColumnWidth
    secondColumnLeft = firstColumnLeft
    thirdColumnLeft = firstColumnLeft
  }

  const commonColumnParams = {
    maxLabelWidth: thinColumnWidth,
    collapsedColumnIndex,
  }

  return {
    first: {
      width: firstColumnWidth,
      left: firstColumnLeft,
      zIndex: 2,
      ...commonColumnParams,
    },
    second: {
      width: secondColumnWidth,
      left: secondColumnLeft,
      zIndex: 1,
      ...commonColumnParams,
    },
    third: {
      width: thirdColumnWidth,
      left: thirdColumnLeft,
      zIndex: 0,
      ...commonColumnParams,
    },
  }
}
