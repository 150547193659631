import React, { useState } from 'react'
import { Button, Input } from 'antd'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TaskOptions from '../../components/Task/TaskOptions'
import TaskActions from '../../components/Task/TaskActions'
import TaskChecklist from '../../components/Task/TaskChecklist'
import TaskAttachments from '../../components/Task/TaskAttachments'
import TaskLinks from '../../components/Task/TaskLinks'
import Subtasks from '../../components/Task/Subtasks'
import RelatedTasks from '../../components/Task/RelatedTasks'
import taskDetailsData from './task-details-fake.json'
import taskListData from './TaskList.json'

const { TextArea } = Input

const TaskDetailsContainer = () => {
  const [taskDescription, setTaskDescription] = useState(taskDetailsData.taskDescription)
  const [taskInformation, setTaskInformation] = useState(taskDetailsData.taskInformation)

  const handleChangeDescription = (e) => {
    setTaskDescription(e.target.value)
  }

  const handleChangeInformation = (e) => {
    setTaskInformation(e.target.value)
  }

  return (
    <div
      className="task-details collapsible__col-content"
    >
      <div className="task-details__task-name">
        <div className="task-name__icon">
          <FontAwesomeIcon icon={['fas', 'palette']} size="1x" color="white" />
        </div>
        <div className="task-name__text">
          {taskDetailsData.taskNumber}
        </div>
      </div>
      <h3 className="task-details__task-title">{taskDetailsData.taskName}</h3>
      <TaskOptions
        created={taskDetailsData.created}
        creator={taskDetailsData.creator}
        manager={taskDetailsData.manager}
        nextReminder={taskDetailsData.nextReminder}
        priority={taskDetailsData.priority}
        lastUpdated={taskDetailsData.lastUpdated}
        assignee={taskDetailsData.assignee}
        due={taskDetailsData.due}
      />
      <TaskActions />
      <div className="task-details__task-description">
        <TextArea
          className="task-details__description-input"
          value={taskDescription}
          onChange={handleChangeDescription}
        />
      </div>
      <TaskChecklist checklist={taskDetailsData.checklist} />
      <div className="task-details__information">
        <TextArea
          className="task-details__information-input"
          value={taskInformation}
          onChange={handleChangeInformation}
        />
      </div>
      <div className="task-buttons">
        <Button className="task-buttons__unready-button">UNREADY</Button>
        <Button>
          <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
        </Button>
        <Button type="primary" className="task-buttons__start-button">START</Button>
      </div>
      <TaskLinks links={taskDetailsData.links} />
      <TaskAttachments attachments={taskDetailsData.attachments} />
      <Subtasks subtasks={taskListData} />
      <RelatedTasks relatedTasks={taskListData} />

    </div>
  )
}

export default observer(TaskDetailsContainer)
