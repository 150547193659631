import React, { useEffect, useState } from 'react'
import { observer, PropTypes as MobXPropTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import CollapsibleColumnWrapper from './CollapsibleColumnWrapper'
import { COLLAPSED_COLUMNS_INDEXES } from '../../constants'
import { getCollapsedColumnsProperties } from '../../../helpers'

const CollapsibleColumnsWrapper = ({
  collapsibleRowRef,
  firstColumnContainer,
  secondColumnContainer,
  thirdColumnContainer,
  width,
}) => {
  const [columnParameters, setColumnParameters] = useState({})
  const [collapsedColumnIndex, setCollapsedColumnIndex] = useState(3)

  const updateColumnWidth = () => {
    const collapsibleRow = collapsibleRowRef.current
    setColumnParameters(getCollapsedColumnsProperties(collapsibleRow, collapsedColumnIndex))
  }

  useEffect(() => {
    updateColumnWidth()
  }, [collapsedColumnIndex, width])

  const onCollapsedSectionClick = (index = COLLAPSED_COLUMNS_INDEXES.third) => {
    if (index === COLLAPSED_COLUMNS_INDEXES.first) {
      setCollapsedColumnIndex(COLLAPSED_COLUMNS_INDEXES.third)
    }
    if (index === COLLAPSED_COLUMNS_INDEXES.third) {
      setCollapsedColumnIndex(COLLAPSED_COLUMNS_INDEXES.first)
    }
  }

  const secondColumnContainerExpanded = {
    ...secondColumnContainer,
    props: {
      ...secondColumnContainer.props,
      onCollapsedSectionClick,
    },
  }

  return (
    <>
      <CollapsibleColumnWrapper
        columnParameters={columnParameters.first}
        index={COLLAPSED_COLUMNS_INDEXES.first}
        onCollapsedSectionClick={onCollapsedSectionClick}
      >
        {firstColumnContainer}
      </CollapsibleColumnWrapper>
      <CollapsibleColumnWrapper
        columnParameters={columnParameters.second}
        index={COLLAPSED_COLUMNS_INDEXES.second}
      >
        {secondColumnContainerExpanded}
      </CollapsibleColumnWrapper>
      <CollapsibleColumnWrapper
        columnParameters={columnParameters.third}
        index={COLLAPSED_COLUMNS_INDEXES.third}
        onCollapsedSectionClick={onCollapsedSectionClick}
      >
        {thirdColumnContainer}
      </CollapsibleColumnWrapper>
    </>
  )
}

CollapsibleColumnsWrapper.propTypes = {
  collapsibleRowRef: MobXPropTypes.objectOrObservableObject.isRequired,
  firstColumnContainer: PropTypes.node.isRequired,
  secondColumnContainer: PropTypes.node.isRequired,
  thirdColumnContainer: PropTypes.node.isRequired,
  width: PropTypes.number,
}

CollapsibleColumnsWrapper.defaultProps = {
  width: document.innerWidth - 80,
}

export default observer(CollapsibleColumnsWrapper)
