import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import Subtask from './Subtask'

const Subtasks = ({ subtasks }) => (
  <div className="task-subtasks">
    <div className="task-subtasks__heading">
      <h4>Subtasks</h4>
      <Button type="link">
        <FontAwesomeIcon size="2x" icon={['fal', 'plus']} />
      </Button>
    </div>
    <div>
      {
        subtasks.map(subtask => (
          <Subtask key={subtask.date} name={subtask.taskName} status={subtask.status} />
        ))
      }
    </div>
  </div>
)

Subtasks.propTypes = {
  subtasks: MobxPropTypes.arrayOrObservableArray,
}

Subtasks.defaultProps = {
  subtasks: [],
}

export default Subtasks
