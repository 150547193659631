import React from 'react'
import PropTypes from 'prop-types'
import { observer, PropTypes as MobXPropTypes } from 'mobx-react'
import { Button } from 'antd'

const CollapsibleColumnWrapper = ({
  children,
  columnParameters,
  index,
  onCollapsedSectionClick,
}) => {
  const {
    collapsedColumnIndex,
    left,
    maxLabelWidth,
    width,
    zIndex,
  } = columnParameters || {}

  const isWide = collapsedColumnIndex !== index

  const sectionStyles = isWide ?
    {
      zIndex,
      minWidth: width,
      maxWidth: width,
      position: 'relative',
      overflow: 'hidden',
      left,
    } :
    {
      minWidth: width,
      zIndex,
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      flexDirection: 'column',
      alignItems: 'flex-end',
      left,
    }

  const narrowElementStyles = {
    width: maxLabelWidth,
    opacity: `${isWide ? 0 : 1}`,
    zIndex: `${isWide ? 0 : 1}`,
  }

  const wideElementStyles = {
    minWidth: width,
    opacity: `${isWide ? 1 : 0}`,
    zIndex: `${isWide ? 1 : 0}`,
  }

  return (
    <div style={sectionStyles} className="collapsible__col">
      <Button
        type="text"
        style={narrowElementStyles}
        className="collapsible__narrow-element"
        onClick={() => onCollapsedSectionClick(index)}
      >
        <span className="collapsible__vertical-title">Collapsed Column</span>
      </Button>
      <div
        className="collapsible__wide-element"
        style={wideElementStyles}
      >
        {children}
      </div>
    </div>
  )
}

CollapsibleColumnWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  columnParameters: MobXPropTypes.objectOrObservableObject,
  index: PropTypes.number.isRequired,
  onCollapsedSectionClick: PropTypes.func,
}

CollapsibleColumnWrapper.defaultProps = {
  columnParameters: {},
  onCollapsedSectionClick: () => null,
}

export default observer(CollapsibleColumnWrapper)
