export const PROJECT_COLORS = [
  '#E02020',
  '#FA6400',
  '#F7B500',
  '#6DD400',
  '#44D7B6',
  '#32C5FF',
  '#0091FF',
  '#B620E0',
]

export const PROJECT_COLORS_OBJECT = {
  Red: '#E02020',
  Orange: '#FA6400',
  Yellow: '#F7B500',
  YellowGreen: '#6DD400',
  Green: '#44D7B6',
  BlueGreen: '#32C5FF',
  Blue: '#0091FF',
  Violet: '#B620E0',
}
