import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import { conditionedClassName } from 'flynk.app.web.core.components/helpers'
import { Button } from 'antd'

const Panel = ({
  children,
  className,
  header,
  icon,
  iconType,
  isExpanded,
  onToggleClick,
  onOpen,
  onFirstOpen,
  onClose,
}) => {
  const [expanded, setExpanded] = useState(isExpanded)
  const [isOverFlow, setIsOverFlow] = useState(isExpanded)
  const firstOpenRef = useRef(false)

  useEffect(() => {
    if (expanded) {
      setTimeout(() => setIsOverFlow(true), 500)
    } else {
      setIsOverFlow(false)
    }
  }, [expanded])

  const headerC = typeof (header) === 'string' ? <span>{header}</span> : header()

  return (
    <div
      className={conditionedClassName({
        'collapse-panel__wrapper': true,
        [className]: className,
      })}
    >
      <div
        className="collapse-panel__header-wrapper"
      >
        <div className="collapse-panel__expanding-wrapper">{headerC}</div>
        <Button
          type="text"
          className="collapse-panel__expanding-icon-wrapper"
          onClick={() => {
            setExpanded(prevValue => !prevValue)
            onToggleClick(expanded)
            if (!expanded) {
              onOpen()
              if (!firstOpenRef.current) {
                onFirstOpen()
                firstOpenRef.current = true
              }
            } else {
              onClose()
            }
          }}
        >
          <FontAwesomeIcon
            className={conditionedClassName({
              'collapse-panel__expanding-icon': true,
              opened: expanded,
            })}
            icon={[iconType || 'far', icon || 'chevron-down']}
          />
        </Button>
      </div>
      <div
        className={conditionedClassName({
          'collapse-panel__expanding-area': true,
          opened: expanded,
          overflow: isOverFlow,
        })}
      >
        {children}
      </div>
    </div>
  )
}

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.func.isRequired,
  ]).isRequired,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClose: PropTypes.func,
  onFirstOpen: PropTypes.func,
  onOpen: PropTypes.func,
  onToggleClick: PropTypes.func,
}

Panel.defaultProps = {
  className: null,
  icon: null,
  iconType: null,
  isExpanded: false,
  onClose: () => null,
  onFirstOpen: () => null,
  onOpen: () => null,
  onToggleClick: () => null,
}

export default observer(Panel)
