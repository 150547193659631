import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
import { observer } from 'mobx-react'
import { conditionedClassName } from 'flynk.app.web.core.components/helpers'
import LogoDeviceSvg from '../Logo/LogoDeviceSvg'

const DetailsModal = ({
  children,
  className,
  onCancel,
  projectColor,
  titleText,
  visible,
}) => (
  <Modal
    className={conditionedClassName({
      'engyn-modal': true,
      [className]: className,
    })}
    footer={null}
    maskClosable
    onCancel={onCancel}
    title={(
      <>
        <LogoDeviceSvg />
        <span className="engyn-modal__title-text">{titleText}</span>
        <span className="engyn-modal__title-left-placeholder" />
      </>
    )}
    visible={visible}
    width={450}
  >
    <div className="engyn-modal__top-color-bar" style={{ backgroundColor: projectColor }} />
    {children}
  </Modal>
)

DetailsModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  projectColor: PropTypes.string.isRequired,
  titleText: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
}

DetailsModal.defaultProps = {
  className: '',
}

export default observer(DetailsModal)
