import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import TaskLink from './TaskLink'

const TaskLinks = ({ links }) => (
  <div className="task-links">
    <div className="task-links__heading">
      <h4>Links</h4>
      <Button type="link">
        <FontAwesomeIcon size="2x" icon={['fal', 'plus']} />
      </Button>
    </div>
    <div>
      {links.map(link => (
        <TaskLink key={link.title} title={link.title} url={link.url} external={link.external} />
      ))}
    </div>
  </div>
)

TaskLinks.propTypes = {
  links: MobxPropTypes.arrayOrObservableArray,
}

TaskLinks.defaultProps = {
  links: [],
}

export default TaskLinks
