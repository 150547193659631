import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'

const TaskActions = () => (
  <div className="task-actions">
    <Button type="link">
      <FontAwesomeIcon size="2x" icon={['fas', 'hand-point-right']} color="lightblue" />
    </Button>
    <Button type="link">
      <FontAwesomeIcon size="2x" icon={['fal', 'flag']} color="grey" />
    </Button>
    <Button type="link">
      <FontAwesomeIcon size="2x" icon={['fal', 'pause']} color="grey" />
    </Button>
    <Button type="link">
      <FontAwesomeIcon size="2x" icon={['fal', 'octagon']} color="red" />
    </Button>
    <Button type="link">
      <FontAwesomeIcon size="2x" icon={['fal', 'exclamation-triangle']} color="grey" />
    </Button>
    <Button type="link">
      <FontAwesomeIcon size="2x" icon={['fal', 'cog']} color="gray" />
    </Button>
  </div>
)

export default TaskActions
