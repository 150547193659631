import React, { useEffect } from 'react'
import { observer, PropTypes as MobxPropTypes } from 'mobx-react'
import PropTypes from 'prop-types'
import Loading from 'flynk.app.web.core.components/components/Loading'
import { useStores } from 'flynk.app.web.core.components/helpers'

const MicroAppBase = ({ resource, children }) => {
  const { authStore, configStore } = useStores()

  useEffect(() => {
    if (!configStore.version) return

    if (!authStore.isLoadedUserContext) {
      const { auth } = resource
      authStore.setAuth(auth)
    }
  })

  return (
    authStore.isLoadedUserContext ?
      <>
        {children}
      </> :
      <Loading />
  )
}

MicroAppBase.propTypes = {
  resource: MobxPropTypes.objectOrObservableObject,
  rootStore: PropTypes.shape({
    authStore: PropTypes.shape({
      isLoadedUserContext: PropTypes.bool.isRequired,
      setAuth: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
}

MicroAppBase.defaultProps = {
  resource: {},
}

export default observer(MicroAppBase)
