import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { conditionedClassName } from 'flynk.app.web.core.components/helpers'
import { MenuContext } from './context'

export const Item = ({ children, className, name, selected }) => {
  const { defaultSelected, menuItems, setMenuItems } = useContext(MenuContext)
  const [hovered, setHovered] = useState(false)
  const thisItemState = menuItems.find(item => item.name === name)

  useEffect(() => {
    setMenuItems(prev => ([
      ...prev,
      {
        name,
        selected: defaultSelected ? name === defaultSelected : selected,
      },
    ]))
  }, [])

  const selectItem = () => {
    setMenuItems(prevItems => prevItems
      .map((item) => {
        if (item.name === name) {
          return {
            ...item,
            selected: true,
          }
        }
        return {
          ...item,
          selected: false,
        }
      }))
  }

  return (
    <div // eslint-disable-line
      className={conditionedClassName({
        'custom-menu-item': true,
        [className]: className,
        'menu-item-hovered': hovered,
        'menu-item-selected': thisItemState?.selected,
      })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={selectItem}
    >
      {children}
    </div>
  )
}

Item.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  selected: PropTypes.bool,
}

Item.defaultProps = {
  className: '',
  selected: false,
}
