import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'

const HeaderMenuDropdown = () => {
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = bool => setVisible(bool)

  const menu = (
    <Menu onClick={() => null}>
      <Menu.Item key="1">Click me!</Menu.Item>
      <Menu.Item key="2">Click me!</Menu.Item>
      <Menu.Item key="3">Click me!</Menu.Item>
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger="click"
    >
      <Button type="link" className="task-list__header-button">
        <FontAwesomeIcon icon={['fal', 'ellipsis-h']} />
      </Button>
    </Dropdown>
  )
}

export default observer(HeaderMenuDropdown)
