import React from 'react'
import PropTypes from 'prop-types'
import ProjectColor from './ProjectColor'
import { PROJECT_COLORS } from '../../constants'

const ProjectColorSelection = ({ selectedColor, handleSelectColor }) => (
  <div className="project-color-selection">
    {
        PROJECT_COLORS.map(color => (
          <ProjectColor
            active={color === selectedColor}
            color={color}
            onSelect={handleSelectColor}
            key={color}
          />
        ))
      }
  </div>
)

ProjectColorSelection.propTypes = {
  selectedColor: PropTypes.string,
  handleSelectColor: PropTypes.func,
}

ProjectColorSelection.defaultProps = {
  selectedColor: '',
  handleSelectColor: () => {},
}

export default ProjectColorSelection
