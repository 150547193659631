import { action, observable } from 'mobx'
import { persist } from 'mobx-persist'
import { RESPONSE_TYPES } from 'flynk.app.web.core.data/constants'
import { Modal } from 'antd'

export default class TaskStore {
  constructor(args) {
    this.rootStore = args.rootStore
    this.rootAPI = args.rootAPI
  }

  // persist items declaration
  @persist('list') @observable taskProjects = []
  @persist('list') @observable taskLists = []
  @persist('list') @observable taskListItems = []

  // loading declaration
  @observable getTaskProjectsLoading = false
  @observable getTaskListsLoading = false
  @observable createTaskListLoading = false
  @observable createTaskProjectLoading = false
  @observable getTaskItemsByListIdLoading = false

  // modal visible declaration
  @observable taskDetailsModalVisible = false
  @observable createProjectModalVisible = false
  @observable createListModalVisible = false

  // action
  @action.bound
  toggleTaskDetailsModalVisible() {
    this.taskDetailsModalVisible = !this.taskDetailsModalVisible
  }

  // action
  @action.bound
  toggleCreateProjectModalVisible() {
    this.createProjectModalVisible = !this.createProjectModalVisible
  }

  // action
  @action.bound
  toggleCreateListModalVisible() {
    this.createListModalVisible = !this.createListModalVisible
  }

  // action
  @action.bound
  async createTaskProject(data) {
    this.createTaskProjectLoading = true

    const ownerId = this.rootStore.profileStore.currentOrganisation.id
    const ownerType = this.rootStore.profileStore.currentOrganisation.type

    try {
      const res = await this.rootAPI.taskAPI.createTaskProject({
        ...data,
        ownerId,
        ownerType,
      })

      if (res) {
        this.rootStore.alertStore.success({
          title: 'Success!',
          content: 'New project has been successfully created',
        })
        this.toggleCreateProjectModalVisible()
        this.getTaskProjects()
      }
    } catch (err) {
      if (err.type === RESPONSE_TYPES.DENIED) {
        Modal.error({
          title: 'Error!',
          content: 'Please check if you confirmed your email/phone!',
        })
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    } finally {
      this.createTaskProjectLoading = false
    }
  }

  // action
  @action.bound
  async createTaskList(data) {
    this.createTaskListLoading = true

    const ownerId = this.rootStore.profileStore.currentOrganisation.id
    const ownerType = this.rootStore.profileStore.currentOrganisation.type

    try {
      const res = await this.rootAPI.taskAPI.createTaskList({
        ...data,
        ownerId,
        ownerType,
      })

      if (res) {
        this.rootStore.alertStore.success({
          title: 'Success!',
          content: 'New list has been successfully created',
        })
        this.toggleCreateListModalVisible()
        this.getTaskLists()
      }
    } catch (err) {
      if (err.type === RESPONSE_TYPES.DENIED) {
        Modal.error({
          title: 'Error!',
          content: 'Please check if you confirmed your email/phone!',
        })
      } else {
        this.rootStore.errorsStore.addError(err)
      }
    } finally {
      this.createTaskListLoading = false
    }
  }

  // action
  @action.bound
  async getTaskLists() {
    this.getTaskListsLoading = true

    const scopeId = this.rootStore.profileStore.currentOrganisation.id
    const scopeType = this.rootStore.profileStore.currentOrganisation.type

    try {
      const res = await this.rootAPI.taskAPI.getTaskLists({ scopeId, scopeType })

      if (res) {
        this.taskLists = res.payload.items
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getTaskListsLoading = false
    }
  }

  // action
  @action.bound
  async getTaskProjects() {
    this.getTaskProjectsLoading = true

    const scopeId = this.rootStore.profileStore.currentOrganisation.id
    const scopeType = this.rootStore.profileStore.currentOrganisation.type

    try {
      const res = await this.rootAPI.taskAPI.getTaskProjects({ scopeId, scopeType })

      if (res) {
        this.taskProjects = res.payload
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getTaskProjectsLoading = false
    }
  }

  // action
  @action.bound
  async getTaskProjectById() {
    this.createProjectModalVisible = !this.createProjectModalVisible
  }

  // action
  @action.bound
  async getTaskItemsByListId(id, params = { skip: 0, take: 20 }) {
    this.getTaskItemsByListIdLoading = true

    try {
      const res = await this.rootAPI.taskAPI.getTaskItemsByListId(id, params)
      if (res) {
        this.taskListItems = res.payload.items
      }
    } catch (err) {
      this.rootStore.errorsStore.addError(err)
    } finally {
      this.getTaskItemsByListIdLoading = false
    }
  }
}
