import React from 'react'
import { Input } from 'antd'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TaskListSelect from './TaskListSelect'

const TaskListSubheader = () => (
  <div className="task-list__subheader-row">
    <Input
      className="task-list__subheader-input task-list__subheader-item"
      placeholder="Name of task"
      prefix={<FontAwesomeIcon icon={['fal', 'search']} />}
    />
    <TaskListSelect
      title="Assigned to"
      defaultValue="anyone"
      options={[
        { label: 'Anyone', value: 'anyone' },
        { label: 'Me', value: 'me' },
        { label: 'Others', value: 'others' },
      ]}
    />
    <TaskListSelect
      title="Status"
      defaultValue="any"
      options={[
        { label: 'Any', value: 'any' },
        { label: 'Exact', value: 'exact' },
      ]}
    />
    <TaskListSelect
      title="Priority"
      defaultValue="all"
      options={[
        { label: 'All', value: 'all' },
        { label: 'High', value: 'high' },
        { label: 'Middle', value: 'middle' },
        { label: 'Low', value: 'low' },
      ]}
    />
    <TaskListSelect
      title="Sort by"
      defaultValue="status"
      options={[
        { label: 'Status', value: 'status' },
        { label: 'Date', value: 'date' },
      ]}
    />
  </div>
)

export default observer(TaskListSubheader)
