import Request from 'flynk.app.web.core.data/helpers/requests'

import AuthAPI from 'flynk.app.web.core.data/api/AuthAPI'
import BankAPI from 'flynk.app.web.core.data/api/BankAPI'
import ClientAppAPI from 'flynk.app.web.core.data/api/ClientAppAPI'
import ClientAppFeatureAPI from 'flynk.app.web.core.data/api/ClientAppFeatureAPI'
import ClientAppShortlistAPI from 'flynk.app.web.core.data/api/ClientAppShortlistAPI'
import InvoiceAPI from 'flynk.app.web.core.data/api/InvoiceAPI'
import IssueAPI from 'flynk.app.web.core.data/api/IssueAPI'
import OrganisationAPI from 'flynk.app.web.core.data/api/OrganisationAPI'
import PaymentAPI from 'flynk.app.web.core.data/api/PaymentAPI'
import ProductAPI from 'flynk.app.web.core.data/api/ProductAPI'
import ProfileAPI from 'flynk.app.web.core.data/api/ProfileAPI'
import SupplierAPI from 'flynk.app.web.core.data/api/SupplierAPI'
import UserAPI from 'flynk.app.web.core.data/api/UserAPI'

import TaskAPI from './TaskAPI'

export default class RootAPI {
  constructor(args) {
    const { rootStore } = args
    const request = new Request({ rootStore })

    this.authAPI = new AuthAPI({ request, rootStore })
    this.bankAPI = new BankAPI({ request, rootStore })
    this.clientAppAPI = new ClientAppAPI({ request, rootStore })
    this.clientAppFeatureAPI = new ClientAppFeatureAPI({ request, rootStore })
    this.clientAppShortlistAPI = new ClientAppShortlistAPI({ request, rootStore })
    this.invoiceAPI = new InvoiceAPI({ request, rootStore })
    this.issueAPI = new IssueAPI({ request, rootStore })
    this.organisationAPI = new OrganisationAPI({ request, rootStore })
    this.paymentAPI = new PaymentAPI({ request, rootStore })
    this.productAPI = new ProductAPI({ request, rootStore })
    this.profileAPI = new ProfileAPI({ request, rootStore })
    this.supplierAPI = new SupplierAPI({ request, rootStore })
    this.taskAPI = new TaskAPI({ request, rootStore })
    this.userAPI = new UserAPI({ request, rootStore })
  }
}
