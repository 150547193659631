import React from 'react'
import PropTypes from 'prop-types'

const ProjectColor = ({ size, color, active, onSelect }) => (
  <div
    role="presentation"
    onClick={() => onSelect(color)}
    className="project-color-selection__item"
    style={{
      borderWidth: active ? '3px' : 0,
      width: size,
      height: size,
      backgroundColor: color,
    }}
  />
)

ProjectColor.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
}

ProjectColor.defaultProps = {
  size: 28,
  color: 'blue',
  active: false,
  onSelect: () => {},
}

export default ProjectColor
