import React from 'react'
import ReactDOM from 'react-dom'
import './index.less'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'mobx-react/batchingForReactDom'
import { init } from './startup'

window.renderTasks = (containerId, history, resource) => {
  ReactDOM.render(
    <App history={history} resource={resource} />,
    document.getElementById(containerId),
  )
  init()
}

window.unmountTask = (containerId) => {
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId))
}

if (!document.getElementById('Tasks-container')) {
  ReactDOM.render(<App />, document.getElementById('root'))
  serviceWorker.unregister()
}
