import React from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import { observer, PropTypes as MobXPropTypes } from 'mobx-react'

const { Option } = Select

const TaskListSelect = ({
  defaultValue,
  options,
  title,
  ...rest
}) => (
  <div className="task-list__select-wrapper task-list__subheader-item">
    <span className="task-list__select-title">{title}</span>
    <Select
      defaultValue={defaultValue}
      bordered={false}
      className="task-list__select"
      {...rest}
    >
      {
        options.map(({ value, label }) => (
          <Option key={value} value={value}>{label}</Option>
        ))
      }
    </Select>
  </div>
)

TaskListSelect.propTypes = {
  defaultValue: PropTypes.node.isRequired,
  options: MobXPropTypes.arrayOrObservableArray.isRequired,
  title: PropTypes.node.isRequired,
}

export default observer(TaskListSelect)
