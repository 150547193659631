import React from 'react'
import { List } from 'antd'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import list from '../../containers/Tasks/TaskList.json'

const { Item } = List

const TaskListItemByPanel = observer(({
  date,
  onCollapsedSectionClick,
  status,
  taskName,
  taskNumber,
  toggleTaskDetailsModalVisible,
}) => (
  <Item
    className="task-item"
    actions={[
      <FontAwesomeIcon
        icon={['fal', 'arrow-square-up']}
        className="task-item__priority-icon"
      />,
      <div className="task-item__date">
        <FontAwesomeIcon
          icon={['fal', 'calendar-check']}
          className="task-item__date-icon"
        />
        {date}
      </div>,
      <div
        className="task-item__status"
      >
        {status}
      </div>,
    ]}
    onClick={() => {
      onCollapsedSectionClick()
      toggleTaskDetailsModalVisible()
    }}
  >
    <div
      className="task-item__left-content"
    >
      <div
        className="task-item__type-number-wrapper"
      >
        <div className="task-item__type-wrapper">
          <FontAwesomeIcon icon={['fas', 'palette']} className="task-item__type" />
        </div>
        <span className="task-item__number">{taskNumber}</span>
      </div>
      <div
        className="task-item__name"
      >{taskName}
      </div>
    </div>
  </Item>
))

TaskListItemByPanel.propTypes = {
  taskNumber: PropTypes.node.isRequired,
  taskName: PropTypes.node.isRequired,
  date: PropTypes.node.isRequired,
  status: PropTypes.node.isRequired,
  onCollapsedSectionClick: PropTypes.func.isRequired,
  toggleTaskDetailsModalVisible: PropTypes.func.isRequired,
}

const TaskListByPanel = ({
  onCollapsedSectionClick,
  toggleTaskDetailsModalVisible,
}) => (
  <List
    dataSource={list}
    renderItem={item => (
      <TaskListItemByPanel
        onCollapsedSectionClick={onCollapsedSectionClick}
        toggleTaskDetailsModalVisible={toggleTaskDetailsModalVisible}
        {...item}
      />
    )}
  />
)

TaskListByPanel.propTypes = {
  onCollapsedSectionClick: PropTypes.func.isRequired,
  toggleTaskDetailsModalVisible: PropTypes.func.isRequired,
}

export default observer(TaskListByPanel)
