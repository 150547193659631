import React from 'react'
import { Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const Subtask = ({ name, status }) => (
  <div className="subtask-item">
    <div className="subtask-item__left-content">
      <div className="subtask-item__type-number-wrapper">
        <div className="subtask-item__actions">
          <Radio />
          <FontAwesomeIcon color="gray" icon={['fal', 'arrow-square-up']} />
        </div>
      </div>
      <span className="subtask-item__name">{name}</span>
    </div>
    <span className="subtask-item__status">{status}</span>
    <FontAwesomeIcon color="orange" size="1x" icon={['fal', 'user-circle']} />
  </div>
)

Subtask.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
}

Subtask.defaultProps = {
  name: '',
  status: '',
}
export default Subtask
