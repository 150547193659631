import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import TaskAttachment from './TaskAttachment'

const TaskAttachments = ({ attachments }) => (
  <div className="task-attachments">
    <div className="task-attachments__heading">
      <h4>Attachments</h4>
      <Button type="link">
        <FontAwesomeIcon size="2x" icon={['fal', 'plus']} />
      </Button>
    </div>
    <div>
      {
        attachments
          .map(attachment => (<TaskAttachment
            fileSize={attachment.size}
            fileName={attachment.name}
            key={attachment.name}
          />))
      }
    </div>
  </div>
)

TaskAttachments.propTypes = {
  attachments: MobxPropTypes.arrayOrObservableArray,
}

TaskAttachments.defaultProps = {
  attachments: [],
}

export default TaskAttachments
