import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const TaskAttachment = ({ fileName, fileSize }) => {
  const parseFileSize = (size) => {
    if (size < 1024) {
      return `${size.toFixed(2)}Kb`
    }
    if (size < 1024 * 1024) {
      return `${(size / 1024).toFixed(2)}Mb`
    }
    if (size < 1024 * 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)}Gb`
    }
    return size
  }

  return (
    <div className="task-attachment">
      <FontAwesomeIcon className="task-attachment__icon" icon={['fal', 'file']} />
      <div className="task-attachment__name">{fileName}</div>
      <div className="task-attachment__size">{parseFileSize(fileSize)}</div>
    </div>
  )
}

TaskAttachment.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
}

export default TaskAttachment
