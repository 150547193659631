import React from 'react'
import { PropTypes as MobxPropTypes, Provider } from 'mobx-react'
import TaskManagementContainer from './containers/Tasks/TaskManagementContainer'
import rootStore from './stores'
import MicroAppBase from './engyn.base.apps.web.shared/MicroAppBase'

function App({ history, resource }) {
  return (
    <Provider {...rootStore}>
      <MicroAppBase resource={resource} rootStore={rootStore}>
        <TaskManagementContainer history={history} />
      </MicroAppBase>
    </Provider>
  )
}

App.propTypes = {
  history: MobxPropTypes.objectOrObservableObject.isRequired,
  resource: MobxPropTypes.objectOrObservableObject.isRequired,
}

export default App
