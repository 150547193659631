import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Item } from './Item'
import { MenuContext } from './context'

const Menu = ({ children, defaultSelected, onSelect }) => {
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    const selectedItem = menuItems.find(el => el.selected)
    if (defaultSelected && menuItems && !menuItems.some(el => el.selected)) {
      setMenuItems(items => items.map((el) => {
        if (el.name === defaultSelected[0]) {
          return {
            ...el,
            selected: true,
          }
        }
        return el
      }))
    }
    if (selectedItem) {
      onSelect(selectedItem)
    }
  }, [menuItems])

  return (
    <MenuContext.Provider
      value={{
        defaultSelected,
        menuItems,
        setMenuItems,
      }}
    >
      <div className="custom-menu">
        {children}
      </div>
    </MenuContext.Provider>
  )
}

Menu.Item = Item

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  defaultSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}

Menu.defaultProps = {
  defaultSelected: null,
}

export default observer(Menu)
