import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Collapse } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStores } from 'flynk.app.web.core.components/helpers'
import HeaderMenuDropdown from '../../components/TaskList/HeaderMenuDropdown'
import TaskListSubheader from '../../components/TaskList/TaskListSubheader'
import TaskListByPanel from '../../components/TaskList/TaskListByPanel'

const { Panel } = Collapse

const TaskListContainer = ({
  onCollapsedSectionClick,
  toggleTaskDetailsModalVisible,
  taskListId,
}) => {
  const [title] = useState('Tomorrow')

  const {
    taskStore: {
      getTaskItemsByListId,
    },
  } = useStores()

  useEffect(() => {
    if (taskListId) {
      getTaskItemsByListId(taskListId.slice(3))
    }
  }, [taskListId])

  const taskListProps = {
    onCollapsedSectionClick,
    toggleTaskDetailsModalVisible,
  }

  return (
    <div
      className="task-list__container collapsible__col-content"
    >
      <div className="task-list__header-row">
        <h3 className="task-list__header">{title}</h3>
        <HeaderMenuDropdown />
      </div>
      <TaskListSubheader />
      <Collapse
        defaultActiveKey={['1', '2', '3']}
        ghost
        expandIcon={({ isActive }) => {
          const panelIcon = isActive ? 'chevron-square-up' : 'chevron-square-down'

          return <FontAwesomeIcon icon={['fal', panelIcon]} />
        }}
      >
        <Panel header="This is panel header 1" key="1">
          <TaskListByPanel {...taskListProps} />
        </Panel>
        <Panel header="This is panel header 2" key="2">
          <TaskListByPanel {...taskListProps} />
        </Panel>
        <Panel header="This is panel header 3" key="3">
          <TaskListByPanel {...taskListProps} />
        </Panel>
      </Collapse>
    </div>
  )
}

TaskListContainer.propTypes = {
  onCollapsedSectionClick: PropTypes.func,
  toggleTaskDetailsModalVisible: PropTypes.func.isRequired,
}

TaskListContainer.defaultProps = {
  onCollapsedSectionClick: () => null,
}

export default observer(TaskListContainer)
