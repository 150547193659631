import React, { useState } from 'react'
import { Button, Drawer } from 'antd'
import { observer } from 'mobx-react'
import { useResizeDetector } from 'react-resize-detector'
import { useStores, useWindowSize } from 'flynk.app.web.core.components/helpers'
import { BREAK_POINTS_NUMBERS } from 'flynk.app.web.core.components/constants'

import TaskManagementNavigationContainer from './TaskManagementNavigationContainer'
import TaskDetailsContainer from './TaskDetailsContainer'
import TaskListContainer from './TaskListContainer'
import CollapsibleColumnsWrapper from '../../engyn.base.apps.web.shared/components/CollapsibleColumns/CollapsibleColumnsWrapper'
import { TASK_MANAGEMENT_NAVIGATION } from '../../engyn.base.apps.web.shared/constants'

const TaskManagementContainer = () => {
  const {
    taskStore: {
      taskDetailsModalVisible,
      toggleTaskDetailsModalVisible,
    },
  } = useStores()

  const [period, selectPeriod] = useState([TASK_MANAGEMENT_NAVIGATION.Focus])
  const [drawerVisible, setDrawerVisible] = useState(false)

  const toggleDrawer = () => setDrawerVisible(oldVisible => !oldVisible)

  const { width, ref: collapsibleRowRef } = useResizeDetector()

  const { isNarrow: isTablet } = useWindowSize(BREAK_POINTS_NUMBERS.xl - 1)
  const { isNarrow: isMobile } = useWindowSize(BREAK_POINTS_NUMBERS.md - 1)

  const handleSelectPeriod = ({ name }) => selectPeriod([name])

  const firstColumnContainer = (
    <TaskManagementNavigationContainer
      period={period}
      handleSelectPeriod={handleSelectPeriod}
    />
  )

  const secondColumnContainer = (
    <TaskListContainer
      taskListId={period[0]}
      toggleTaskDetailsModalVisible={toggleTaskDetailsModalVisible}
    />
  )

  const thirdColumnContainer = <TaskDetailsContainer />

  let columns = (
    <>
      {firstColumnContainer}
      {secondColumnContainer}
      {thirdColumnContainer}
    </>
  )

  if (isTablet && !isMobile) {
    columns = (
      <CollapsibleColumnsWrapper
        collapsibleRowRef={collapsibleRowRef}
        firstColumnContainer={firstColumnContainer}
        secondColumnContainer={secondColumnContainer}
        thirdColumnContainer={thirdColumnContainer}
        width={width}
      />
    )
  }

  const footer = isMobile &&
    <div className="task-mobile-footer__wrapper">
      <Button
        type="primary"
        onClick={toggleDrawer}
      >Open Drawer
      </Button>
    </div>

  const drawerLeft = isMobile &&
    <Drawer
      title="Task Management Navigation"
      placement="left"
      onClose={toggleDrawer}
      visible={drawerVisible}
      getContainer={false}
      style={{ position: 'absolute' }}
      width="100vw"
      className="task-drawer"
    >
      {firstColumnContainer}
    </Drawer>

  if (isMobile) {
    columns = secondColumnContainer
  }

  const drawerRight = isMobile && (
    <Drawer
      title="Task Details"
      placement="right"
      onClose={toggleTaskDetailsModalVisible}
      visible={taskDetailsModalVisible}
      getContainer={false}
      style={{ position: 'absolute' }}
      width="100vw"
      className="task-details-drawer"
    >
      {thirdColumnContainer}
    </Drawer>
  )

  return (
    <>
      <div
        className="task-management-wrapper collapsible__row"
        ref={collapsibleRowRef}
      >
        {columns}
      </div>
      {drawerRight}
      {footer}
      {drawerLeft}
    </>
  )
}

export default observer(TaskManagementContainer)
